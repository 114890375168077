import { api } from '@/services/axios'

export default {
  async exportAssessmentList({commit}, payload) {
      commit('errorsMutation', [], { root: true })
      return await new Promise((resolve, reject) => {
        api.post(`/admin/assessment/${payload.assessment_id}/list/export`, payload, { responseType: 'blob' }).then(res => {
          console.log(res.data)
          resolve(res.data)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
  },
  
  async exportAssessmentResponses({commit}, payload) {
      commit('errorsMutation', [], { root: true })
      return await new Promise((resolve, reject) => {
        api.post(`/admin/assessment/${payload.assessment_id}/responses/export`, payload, { responseType: 'blob' }).then(res => {
          resolve(res.data)
        }).catch(err => {
          commit(
            'errorsMutation', 
            err.response.data.message, 
            { root: true }
          )
          reject(err.response.data.message)
        })
      })
  },

  async getAdminAssessmentAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/assessment/${payload}`).then(res => {
        commit('assessmentQuestionsMutation', res.data.data.assessment_questions)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.message, 
          { root: true }
        )
        reject(err.response.data.message)
      })
    })
  },
    

  async getAssessmentAnswersAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/assessment/${payload.assessment_id}/${payload.assessment_question_id}/answers`, { params: payload }).then(res => {
        commit('assessmentAnswersMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.message, 
          { root: true }
        )
        reject(err.response.data.message)
      })
    })
  },
}