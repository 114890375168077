export default function() {
  return {
    users: [],
    courses: [],
    classes: [],
    registration_tables: [],
    columns: [],
    filtered_users: [],
    enrollees: [],
    ticketCount: 0,
    tickets: [],
    course_evaluations: [],
    modules: [],
    grading: [],
    gradingUsers: [],
    assessment_questions: [],
    assessment_answers: [],
    questions: [],
    evaluation_answers: []
  }
}