import { api } from '@/services/axios'

export default {
  async getTickets({commit}) {
    await api.get(`/user/tickets`)
    .then((res) => {
      commit('ticketMutation', res.data.data)
    }).catch((err) => {
    });
  },

  async addTickets({commit, dispatch}, payload) {
    const config = { 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/tickets`, payload, config).then(res => {
        dispatch('getTickets')
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.errors)
      })
    })
  }
}