import router from "@/router"

export function usersMutation(state, payload) {
  state.users = payload
}

export function registrationTablesMutation(state, payload) {
  state.registration_tables = payload
}

export function columnsMutation(state, payload) {
  state.columns = payload
}

export function classesMutation(state, payload) {
  state.classes = payload
}

export function coursesMutation(state, payload) {
  state.courses = payload
}

export function filteredUsersMutation(state, payload) {
  state.filtered_users = payload
}

export function tenantMutation(state, payload) {
  state.tenant = payload
}

export function enrolleesMutation(state, payload) {
  state.enrollees = payload
}

export function ticketsMutation(state, payload) {
  state.tickets = payload
}

export function ticketCountMutation(state, payload) {
  state.ticketCount = payload
}

export function modulesMutation(state, payload) {
  state.modules = payload
}

export function courseEvaluationsMutation(state, payload) {
  state.course_evaluations = payload
}

export function gradingMutation(state, payload) {
  state.grading = payload
}

export function gradingAssessmentUsersMutation(state, payload) {
  state.gradingUsers = payload
}

export function assessmentQuestionsMutation(state, payload) {
  state.assessment_questions = payload
}

export function assessmentAnswersMutation(state, payload) {
  state.assessment_answers = payload
}

export function evaluationQuestionMutation(state, payload) {
  state.questions = payload
}

export function evaluationAnswersMutation(state, payload) {
  state.evaluation_answers = payload
}

