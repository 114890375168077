import { api } from '@/services/axios'

export default {
  async getEnrolledCoursesAction({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.get('/user/courses', { params: payload }).then(res => {
        if(payload) {
          let result = res.data.data
          commit('coursesMutation', result.data)
          commit('paginationMutation', {
            pageCount: result.last_page,
            paginate: result.per_page,
            page: result.current_page,
            totalCount: result.total
          })
        } else {
          commit('coursesMutation', res.data.data)
        }
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async getCoursesAction({commit}) {
    await api.get('/user/courses')
    .then((res) => {
      commit('coursesMutation', res.data.data)
    }).catch((err) => {
      
    });
  },

  async showCourseAction({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/user/courses/${payload}`, { params: { learning_material: 1 }}).then(res => {
        let course = res.data.data
        commit('objectMutation', res.data.data)

        let modules = []
        let content = []

        res.data.data.modules.forEach((module, i) => {
          modules.push({prerequisite_set: i,...module, final: true})
          module.topics.forEach(module_topics => {
            module_topics.module_is_locked = module.module_is_locked
            content.push(module_topics)
            content.push(...module_topics.topic_has_assessments)
            module_topics.sub_topics.forEach(module_subtopics => {
              module_topics.module_is_locked = module.module_is_locked
              module_subtopics.module_id = module_topics.module_id
              content.push(module_subtopics)
              content.push(...module_subtopics.sub_topic_has_assessments)
            })
          })
          content.push(...module.module_evaluations)
        })

        content.push(...res.data.data.course_evaluations)

        commit('modulesMutation', modules)
        commit('contentMutation', content)
        commit('evaluationsMutation', res.data.data.course_evaluations)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async showNextContentAction({commit, dispatch}, payload) {
    await api.post(`/user/course/progress/controls`, payload)
    .then(res => {
      commit('materialMutation', res.data.data)
      dispatch('getCoursesAction')
      // console.log(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async updateCourseColor({commit, dispatch}, payload) {
    await api.post(`/user/course-card/color`, payload)
    .then(res => {
      dispatch('getCoursesAction')
    }).catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },

  async updateCourseFavorite({commit, dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/favorite`, payload).then(res => {
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async generateCertificateAction({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/generate/certificate`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getTransactionHistory({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/transaction-history/invoices?page=${payload.page}&paginate=${payload.paginate}`, payload).then(res => {
        let _arr = []
        res.data.data.data.forEach(_course => {
          _arr.push({
            id: _course.id,
            course_id: _course.course.id,
            reference_id: _course.reference_id,
            title: _course.course.title ? _course.course.title : 'Untitled',
            status: _course.status,
            date: _course.updated_at,
            amount: _course.course.price,
            uuid: _course.course.uuid,
            nomination_form: _course.nomination_form,
            mode_of_payment: _course.mode_of_payment,
            official_receipt_no: _course.official_receipt_no
          })
        })
        commit('transactionHistoryMutation', _arr)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async enrollCourse({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/enroll`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getCourseProgressAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/course/${payload}/progress`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.message)
      })
    })
  },

  async markCourseAsCompletedAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/mark-as-complete`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async showCourseTimelineAction({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/user/courses/${payload}`, { params: { learning_material: 0 }}).then(res => {
        let course = res.data.data
        commit('objectMutation', res.data.data)

        let modules = []
        let content = []

        res.data.data.modules.forEach((module, i) => {
          modules.push({prerequisite_set: i,...module, final: true})
          module.topics.forEach(module_topics => {
            module_topics.module_is_locked = module.module_is_locked
            content.push(module_topics)
            content.push(...module_topics.topic_has_assessments)
            module_topics.sub_topics.forEach(module_subtopics => {
              module_topics.module_is_locked = module.module_is_locked
              content.push(module_subtopics)
              content.push(...module_subtopics.sub_topic_has_assessments)
            })
          })
          content.push(...module.module_evaluations)
        })

        content.push(...res.data.data.course_evaluations)

        commit('modulesMutation', modules)
        commit('contentMutation', content)
        commit('evaluationsMutation', res.data.data.course_evaluations)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async saveDoneProgressAction({commit, state}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/save/content`, payload).then(res => {
        state.content.forEach(_content => {
          if(_content.uuid === payload.model_uuid) {
            _content.student_done_status = true
          }
        })
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  async getModuleContentAction({commit, state}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/course/module/${payload}`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  async getCourseZoomMeetingsAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/course/${payload}/zoom-meetings`).then(res => {
        commit('zoomMeetingsMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.message)
      })
    })
  },
  
}