import { api, role } from '@/services/axios'
import axios from 'axios'

const endPoint = () => {
  let end_point = '/'
  if(role() == 'super-admin') end_point = '/super-admin/'
  return end_point;
}

export async function registerUserAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`/register`, payload.form).then(res => {
      resolve(res.data.data)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function authAction({commit}) {  
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.get(`${endPoint()}user`).then(res => {
      commit('authMutation', res.data)
      resolve(res.data.data)
    }).catch(err => {
      console.log(err.response.status);
    // if(err.response.status === 403) {
    //   return location.href='/forbidden'}
    // console.log(err.response.data.errors);
    })
  })
}

export async function loginAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`/login`, payload).then(res => {
      resolve(res.data.data)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function logoutAction({commit}) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`${endPoint()}logout`).then(res => {
      commit('signoutMutation') 
      resolve(res.data.data)
    }).catch(err => {
      console.log(err.response.status);
    // if(err.response.status === 403) {
    //   return location.href='/forbidden'}
    // console.log(err.response.data.errors);
    })
  })
}

export async function sendVerificationMailAction({commit}) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`/email/verification-notification`).then(res => {
      resolve(res.data.message)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  })
}

export async function resendverificationMailAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    let axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${payload.token}`
      },
      baseURL: process.env.VUE_APP_TENANT_API
    })

    axiosInstance.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*'
    }

    axiosInstance.post(`/email/verification-notification`).then(res => {
      resolve(res.data.message)
    }).catch(err => {

      console.log(err)
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function verifyEmailAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    let axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${payload.token}`
      },
      baseURL: process.env.VUE_APP_TENANT_API
    })

    axiosInstance.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*'
    }

    axiosInstance.get(`/email/verify/${payload.id}/${payload.hash}`).then(res => {
      resolve(res.data.message)
    }).catch(err => {

      console.log(err)
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function changeUserPasswordAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.put(`/update/password`, payload.form).then(res => {
      resolve(res.data.message)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function changePasswordAction({commit, dispatch}, payload) {  
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.put(`${endPoint()}update/password`, payload).then(res => {
      dispatch('authAction')
      resolve(res.data.message)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
  // await api.put(`${endPoint()}update/password`, payload)
  // .then(res => {
  //   // console.log(res.data);
  //   dispatch('authAction')
  // })
  // .catch(err => {
  //   commit('errorsMutation', err.response.data.errors)
    
  // })
}

export async function forgotPasswordAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`/forgot-password`, payload).then(res => {
      resolve(res.data.message)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function resetPasswordAction({commit}, payload) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.post(`/reset-password`, payload).then(res => {
      resolve(res.data.message)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.status)
    })
  })
}

export async function getCustomizationAction({commit}){
  let image_keys = ['horizontal_logo', 'vertical_logo', 'sidebar_logo', 'login_logo', 'sidebar_photo', 'favicon', 'side_carousel']
  let color_keys = ['navigation_background_color', 'navigation_banner_color', 'navigation_link_color', 'navigation_text_icon_color', 'navigation_link_inactive_color', 'sidebar_color', 'mini_navigation_background_color', 'mini_navigation_link_color', 'mini_navigation_icon_color', 'mini_navigation_inactive_link_color', 'primary_buttons_color']
  let customization = {}
  let axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_TENANT_API
  })

  axiosInstance.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*'
  }

  return await new Promise((resolve, reject) => {
    let url = `${process.env.VUE_APP_TENANT_API}/tenants/info`

    axios.get(url).then(res => {
      commit('tenantMutation', res.data.data.info.organization)

      image_keys.forEach(key => {
        if(res.data.data.images.findIndex(_item => _item.image_key === key) != -1) {
          if(key === 'favicon'){
            var favicon = document.querySelector('link[rel="icon"][sizes="16x16"]');
            favicon.href = res.data.data.images.find(_item => _item.image_key === key).url
            var favicon = document.querySelector('link[rel="icon"][sizes="32x32"]');
            favicon.href = res.data.data.images.find(_item => _item.image_key === key).url
          }
          customization[key] = res.data.data.images.find(_item => _item.image_key === key).url
        }
        if(key === 'side_carousel'){
          res.data.data.images.filter(_item => _item.image_key.includes('side_carousel')).forEach(_item => {
            customization[_item.image_key] = _item.url
          })
        }
      })

      color_keys.forEach(key => {
        if(res.data.data.hasOwnProperty(key)) {
          if(res.data.data[key] !== '' || !res.data.data[key]) {
            customization[key] = res.data.data[key]
          }
        }
      })

      if(Object.keys(customization).length !== 0) {
        localStorage.setItem('customization', JSON.stringify(customization) )
        commit('customizationMutation', customization)
      }

      localStorage.setItem('organization', res.data.data.info.organization )

      resolve(customization)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.status)
    })
  })
}

export async function otherCoursesAction({commit, dispatch}, payload) {
  let courses = []
  await api('tenants/courses', { params: { is_free: false} }).then(res => {
    courses = res.data.data
  }) 
  .catch(err => {
    return commit(
      'errorsMutation', 
      err.response.data.errors, 
      { root: true }
    )
  })
  await api('tenants/courses', { params: { is_free: true} }).then(res => {
    res.data.data.forEach(item => {
      if(courses.every(course => course.id !== item.id)) {
        courses.push(item)
      }
    })
    commit('otherCoursesMutation', courses)
  })
  .catch(err => {
    return commit(
      'errorsMutation', 
      err.response.data.errors, 
      { root: true }
    )
  })
}

export async function getOtherCourseAction({commit}, payload) { 
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api(`tenants/courses/${payload}`).then(res => {
      commit('courseMutation', res.data.data)
      resolve(res.data.data)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function getRegistrationTables({commit}) {
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api(`/tenants/custom-registration/pages`).then(res => {
      commit('customRegistrationMutation', res.data.data)
      resolve(res.data.data)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}

export async function updateProfileDataAction({commit}, payload){
  commit('errorsMutation', [], { root: true })
  return await new Promise((resolve, reject) => {
    api.put(`/update/details`, payload).then(res => {
      resolve(res.data)
    }).catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
      reject(err.response.data.errors)
    })
  })
}