import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import user from './actions/user'
import courses from './actions/courses'
import classes from './actions/classes'
import registration from './actions/registration'
import enrollment from './actions/enrollment'
import tickets from './actions/tickets'
import student_output from './actions/student_output'
import grading from './actions/grading'
import course_evaluation from './actions/course_evaluation'
import module_evaluation from './actions/module_evaluation'
import assessment from './actions/assessment'

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions: {
    ...user,
    ...courses,
    ...classes,
    ...registration,
    ...enrollment,
    ...tickets,
    ...student_output,
    ...grading,
    ...assessment,
    ...course_evaluation,
    ...module_evaluation
  }
}