import { api } from '@/services/axios'

export default {
  async getNotification({commit}) {
    await api.get(`/user/notifications`)
    .then((res) => {
      commit('notificationMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async markNotification({commit, dispatch}, payload) {
    await api.post(`/user/mark/notification`, payload)
    .then((res) => {
        dispatch('getNotification')
    }).catch((err) => {
        console.log(err);
    });
  },

  async markAllAsRead({commit, dispatch}){
    await api.get(`/user/mark/all/notifications`)
    .then((res) => {
        dispatch('getNotification')
    }).catch((err) => {
        console.log(err);
    });
  }
}