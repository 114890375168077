import { api } from '@/services/axios'

export default {
//   async addClassAction({commit, dispatch}, payload) {
//     await api.post('/admin/class-categories', payload)
//     .then(res => {
//       // commit('successMutation', [], { root: true })
//       dispatch('getClassesAction')
//       console.log(res.data);
//     })
//     .catch(err => {
//       commit(
//         'errorsMutation', 
//         err.response.data.errors, 
//         { root: true }
//       )
//     })
//   },
  async getCoursesAction({commit}) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get('/tenants/courses').then(res => {
        commit('coursesMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async getCoursesUserCount({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get('/admin/courses', {params: payload }).then(res => {
        if(payload.filter === 'Total Users per Course') {
          commit('coursesMutation', res.data.data.data)
        } else {
          commit('coursesMutation', res.data.data)
        }
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  }

//   async updateClassesAction({commit, dispatch}, payload) {
//     await api.put(`/admin/class-categories/${payload.id}`, payload)
//     .then( res => {
//       dispatch('getClassesAction')
//     })
//     .catch(err => {
//       return commit(
//         'errorsMutation', 
//         err.response.data.errors, 
//         { root: true }
//       )
//     })
//   },

//   async deleteClassAction({commit, dispatch}, payload) {
//     await api.delete(`/admin/class-categories/${payload.id}`, payload)
//     .then( res => {
//       dispatch('getClassesAction')
//     })
//     .catch(err => {
//       return commit(
//         'errorsMutation', 
//         err.response.data.errors, 
//         { root: true }
//       )
//     })
//   }
  
}