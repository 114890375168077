import { middleware } from './middleware'

export const AdminRoutes = [
  {
    path: '/admin',
    component: () => import('../layouts/Main.vue'),
    redirect: { name: 'Admin Dashboard' },
    children: [
      {
        path: '/admin/dashboard',
        name: 'Admin Dashboard',
        component: () => import('../views/admin/Dashboard.vue'),
        meta: {
          childname: 'Admin Dashboard',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/user-management',
        name: 'Admin User Management',
        component: () => import('../views/admin/UserManagement.vue'),
        meta: {
          childname: 'Admin User Management',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/enrollment',
        name: 'Admin Course Enrollment',
        component: () => import('../views/admin/enrollment/CourseEnrollment.vue'),
        meta: {
          childname: 'Admin Course Enrollment',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/enrollments/:course_uuid',
        name: 'Admin User Enrollment',
        component: () => import('../views/admin/enrollment/CourseStudentsEnrollment.vue'),
        meta: {
          parent: 'Admin Course Enrollment',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      
      {
        path: '/admin/student-outputs',
        name: 'Admin Student Outputs',
        component: () => import('../views/common/student_output/StudentOutput1.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Student Outputs',
          collapse: false,
        },
      },

      {
        path: '/admin/student-outputs/:course_uuid/enrollees',
        name: 'Admin Student Outputs Enrollees',
        component: () => import('../views/common/student_output/StudentOutputEnrollees.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Student Outputs',
          collapse: false,
        },
      },

      {
        path: '/admin/student-outputs/:course_uuid/enrollee/:user_id',
        name: 'Admin Student Outputs Enrollee Progress',
        component: () => import('../views/common/student_output/StudentOutputEnrolleeProgress.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Student Outputs',
          collapse: false,
        },
      },

      {
        path: '/admin/grading',
        name: 'Admin Grading',
        component: () => import('../views/teacher/Grading.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },
      
      {
        path: '/admin/grading/:id',
        name: 'Admin Grading Course',
        component: () => import('../views/common/grading/GradingAssessment.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },
      {
        path: '/admin/grading/:id/survey/:type/:survey_id',
        name: 'Admin Survey',
        component: () => import('../views/common/grading/GradingSurvey.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },
      {
        path: '/admin/:id/survey/:type/:survey_id/:student_id',
        name: 'Admin Survey Student',
        component: () => import('../views/common/grading/GradingSurveyStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },

      {
        path: '/admin/grading/:course_id/assessment/:id',
        name: 'Admin Grading Assessment',
        component: () => import('../views/common/grading/GradingAssessmentSummary.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },
      {
        path: '/admin/grading/:course_id/assessment/:id/user/:user_id',
        name: 'Admin Grading Assessment User',
        component: () => import('../views/common/grading/GradingStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Admin Grading',
          collapse: false,
        },
      },

      {
        path: '/admin/class-category',
        name: 'Admin Class Category',
        component: () => import('../views/admin/ClassCategory.vue'),
        meta: {
          childname: 'Admin Class Category',
          collapse: false,
          title_append: 'Customization',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/ticket',
        name: 'Admin Ticket',
        component: () => import('../views/admin/Ticket.vue'),
        meta: {
          childname: 'Admin Ticket',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/registration',
        name: 'Admin Registration',
        component: () => import('../views/admin/Registration.vue'),
        meta: {
          childname: 'Admin Registration',
          collapse: false,
          title_append: 'Customization',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/account-settings',
        name: 'Admin Settings',
        component: () => import('../views/admin/AccountSettings.vue'),
        redirect: {name: 'Admin Account Profile'},
        meta: {
          childname: 'Admin Settings',
          collapse: false,
          title_append: '',
          middleware: [middleware],
        },
        children: [
          {
            path: 'profile',
            name: 'Admin Account Profile',
            component: () => import('../views/user/Profile.vue'),
            meta: {
              parent: 'Admin Settings',
              collapse: false,
              middleware: [middleware],
            },
          },
          {
            path: 'change-password',
            name: 'Admin Change Password',
            component: () => import('../views/user/ChangePassword.vue'),
            meta: {
              parent: 'Admin Settings',
              collapse: false,
              middleware: [middleware],
            },
          }
        ]
      },
    ]
  }
]
