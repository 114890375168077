<template>
  <v-app id="app">
    <!-- <iframe v-if="landing_path !== ''" style="display:none;" :src="`${landing_path}/`" id="ifr"></iframe> -->
    <router-view/>
  </v-app>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

// window.addEventListener('message', function(event) {
//   if (event.origin === process.env.VUE_APP_LANDING_DOMAIN) {
//     if (event.data) {
//       let { key, value, action } = event.data
//       if(action === 'set') {
//         localStorage.setItem(key, value);
//       } else if (action === 'remove') {
//         localStorage.removeItem(key);
//       }
//     }
//   }
// });

export default {
  data: () => ({
    images: {},
    image_keys: ['horizontal_logo', 'vertical_logo', 'sidebar_logo', 'login_logo', 'favicon', 'side_carousel'],
    color_keys: ['navigation_background_color', 'navigation_banner_color', 'navigation_link_color', 'navigation_text_icon_color', 'navigation_link_inactive_color', 'sidebar_color', 'mini_navigation_background_color', 'mini_navigation_link_color', 'mini_navigation_icon_color', 'mini_navigation_inactive_link_color', 'primary_buttons_color']
  }),
  mounted(){
    document.title =  process.env.VUE_APP_NAME;

    let _customization = localStorage.getItem('customization')
    let _organization = localStorage.getItem('organization')

    if(_organization) {
      this.tenantMutation(localStorage.getItem('organization'))
    }
    if(_customization) {
      this.customizationMutation(JSON.parse(_customization))      
    }

    if (!_customization || !_organization) {
      // let axiosInstance = axios.create({
      //   baseURL: process.env.VUE_APP_TENANT_API
      // })

      // axiosInstance.defaults.headers.common = {
      //   'X-Requested-With': 'XMLHttpRequest',
      //   'Access-Control-Allow-Origin': '*'
      // }
      // axiosInstance.get('tenants/info').then(res => {
      this.getCustomizationAction().then(res => {
        if(!_organization) {
          localStorage.setItem('organization', res.data.data.info.organization)
        }
        this.image_keys.forEach(key => {
            if(res.data.data) {
              if(key === 'side_carousel'){
                res.data.data.images.filter(_item => _item.image_key.includes('side_carousel')).forEach((_item, key) => {
                  this.images[key] = _item
                })
              } else if(res.data.data && res.data.data.images.findIndex(_item => _item.image_key === key) != -1) {
                this.images[key] = res.data.data.images.find(_item => _item.image_key === key).url
              } else {
                this.images[key] = null
              }
            } else {
                this.images[key] = null
              }
          
        })

        let custom_string = `{`

        this.image_keys.forEach(key => {
          if(key === 'side_carousel'){
            this.images.filter(_item => _item.image_key.includes('side_carousel')).forEach((_item, key) => {
              custom_string = custom_string.concat(`"${key}": "${this.images[key]}",`)
            })
          } else if(this.images[key]) {
            custom_string = custom_string.concat(`"${key}": "${this.images[key]}",`)

            if(key === 'favicon'){
              var favicon = document.querySelector('link[rel="icon"][sizes="16x16"]');
              favicon.href = this.images[key]
              var favicon = document.querySelector('link[rel="icon"][sizes="32x32"]');
              favicon.href = this.images[key]
            }
          }
        })

        this.color_keys.forEach(key => {
          if(res.data.data.hasOwnProperty(key)) {
            if(res.data.data[key] !== '' || !res.data.data[key]) {
              custom_string = custom_string.concat(`"${key}": "${this.color_keys[key]}",`)
            }
          }
        })

        custom_string = custom_string.concat(`}`)
        
        if(Object.keys(JSON.parse(custom_string)).length !== 0) {
          localStorage.setItem('customization', JSON.stringify(custom_string) )
          this.customizationMutation(JSON.parse(custom_string))
        }
      })
    }
  },

  methods: {
    ...mapActions(['getCustomizationAction']),
    ...mapMutations(['tenantMutation', 'customizationMutation']),
  }
}
</script>


