import { api } from '@/services/axios'

export default {
    async getInstructorAnnouncementsAction({commit}) {
      commit('errorsMutation', [], { root: true })
      return await new Promise((resolve, reject) => {
        api.get('/instructor/announcements').then(res => {
          commit('announcementsMutation', res.data.data)
          resolve(res.data.data)
        }).catch(err => {
          commit(
            'errorsMutation', 
            err.response.data.errors, 
            { root: true }
          )
          reject(err.response.data.errors)
        })
      })
    },

    async updateAccouncementAction({dispatch}, payload) {
      commit('errorsMutation', [], { root: true })
      return await new Promise((resolve, reject) => {
        api.put(`/instructor/announcements/${payload.id}`, payload).then(res => {
          dispatch('getInstructorAnnouncementsAction')
          resolve(res.data.data)
        }).catch(err => {
          commit(
            'errorsMutation', 
            err.response.data.errors, 
            { root: true }
          )
          reject(err.response.data.errors)
        })
      })
    }
}