const routes = [
  {
    subheader: 'DASHBOARD',
    lists: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        route: 'User Dashboard'
      },
      {
        
        title: 'My Courses',
        icon: 'mdi-book-open-outline',
        route: 'User My Courses'
      },
      {
        title: 'My List',
        icon: 'mdi-text-box-outline',
        route: 'User List'
      },
      {
        title: 'My Calendar',
        icon: 'mdi-calendar-outline',
        route: 'User Calendar'
      },
      // {
      //   title: 'Forum',
      //   icon: 'mdi-forum-outline',
      //   route: 'User Grades'
      // },
      {
        title: 'Inbox',
        icon: 'mdi-message-outline',
        route: 'User Inbox'
      },

      /* {
        title: 'Transaction History',
        icon: 'mdi-update',
        route: 'User Transaction History'
      }, */
      // {
      //   title: 'My Files',
      //   icon: 'mdi-bookmark-outline',
      //   route: 'User Bookmarks'
      // },
    ]
  },
  // {
  //   subheader: 'COMMUNICATION',
  //   lists: [
  //     {
  //       title: 'Announcements',
  //       icon: 'mdi-bullhorn',
  //       route: 'User Announcements'
  //     },
  //     {
  //       title: 'Live Sessions',
  //       icon: 'mdi-video-plus',
  //       route: 'User Live Session'
  //     },
  //     {
  //       title: 'Inbox',
  //       icon: 'mdi-mail',
  //       route: 'Teacher Live Session'
  //     },
  //   ]
  // },
  // {
  //   subheader: 'HELP DESK',
  //   lists: [
  //     {
  //       title: 'Ticketing Support',
  //       icon: 'mdi-ticket-account',
  //       route: 'User Tickets' 
  //     },
  //     {
  //       title: 'FAQS',
  //       icon: 'mdi-help-circle',
  //       route: ''
  //     },
  //   ]
  // },
  // {
  //   subheader: 'ACCOUNT AND SECURITY',
  //   lists: [
  //     {
  //       title: 'Account Settings',
  //       icon: 'mdi-account',
  //       route: 'Teacher Settings' 
  //     },
  //   ]
  // },
]

export default {
  install(Vue) {
    Vue.prototype.$userRoutes = routes
  }
}