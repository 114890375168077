export default function() {
  return {
    object: [],

    courses: [],
    modules: [],
    content: [],
    evaluations: [],
    zoom_meetings: [],

    announcements: [],

    contacts: [],
    chat_lists: [],
    group_lists: [],
    messages: [],
    assessments: [],
    
    material: [],

    startAssessment: {
      //call to action end time-over
      action: '',
      status: false
    },

    chat_wrapper: null,
    calendar: [],
    files: [],
    notification: [],
    tickets: [],
    errors: [],
    meeting: null,
    cart: [],

    transaction_history: [],
    pagination: {
      pageCount: 1,
      paginate: 10,
      page: 1,
      totalCount: 0
    }
  }
}