export const LandingRoutes = [
  {
    path: '/',
    name: 'Educat',
    component: () => import('../layouts/landing/Main.vue'),
    redirect: { name: 'Home' },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/landing/Home.vue'),
      },
      {
        path: '/about-us',
        name: 'About Us',
        component: () => import('../views/landing/AboutUs.vue'),
      },
      {
        path: '/contact-us',
        name: 'Contact Us',
        component: () => import('../views/landing/Contact.vue'),
      },
      
    ]
  }
]