import { middleware } from './middleware'

export const MobileUserRoutes = [
  {
    path: '/m/dashboard',
    component: () => import('../layouts/mobile/Main.vue'),
    redirect: { name: 'Mobile User Dashboard' },
    children: [
      {
        path: '',
        name: 'Mobile User Dashboard',
        component: () => import('../views/mobile/user/Dashboard.vue'),
        meta: {
          middleware: [middleware],
          parent: 'Mobile User Dashboard',
          top: true,
          
        }
      },
      {
        path: '/m/my-courses',
        name: 'Mobile User My Courses',
        component: () => import('../views/mobile/user/MyCourses.vue'),
        meta: {
          middleware: [middleware],
          parent: 'Mobile User My Courses',
          top: true,
        }
      },
      {
        path: '/m/course/:uuid',
        name: 'Mobile User Course Details',
        component: () => import('../views/mobile/user/CourseDetails.vue'),
        meta: {
          middleware: [middleware],
          parent: 'Mobile User My Courses',
          top: false,
        }
      },

    ]
  }
]