import { api } from '@/services/axios'

export default {
  async addMeetingAction({commit, dispatch}, payload) {
    // await api.post('instructor/zoom-meetings/request/access_token')
    // .then((res) => {
    //     console.log(res.data.data)
        // let _access_token = res.data.data
        // payload.access_token = _access_token
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post('instructor/zoom-meetings', payload).then(res => {
        dispatch('getMeetingsAction', {course_id: payload.course_id})
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
        // await api.post('instructor/zoom-meetings', payload).then((res) => {
        //     console.log(res.data.data)
        //     dispatch('getMeetingsAction', {course_id: payload.course_id})
        // }).catch((err) => {
        //     console.log(err);
        // });
  },

  async getMeetingsAction({commit}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get('instructor/zoom-meetings').then(res => {
        commit('meetingsMutation', res.data.data.filter(_meeting => _meeting.course_id == payload.course_id))
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
    // await api.get('instructor/zoom-meetings').then((res) => {
    //     commit('meetingsMutation', res.data.data.filter(_meeting => _meeting.course_id == payload.course_id))
    // }).catch((err) => {
    //     console.log(err);
    // });
  },

  async deleteMeetingAction({commit, dispatch}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.delete(`instructor/zoom-meetings/${payload.id}`).then(res => {
        dispatch('getMeetingsAction', {course_id: payload.course_id})
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
    // await api.delete(`instructor/zoom-meetings/${payload.id}`).then((res) => {
    //   dispatch('getMeetingsAction', {course_id: payload.course_id})
    // }).catch((err) => {
    //     console.log(err);
    // });
  },

  async updateMeetingAction({commit, dispatch}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.patch(`instructor/zoom-meetings/${payload.id}`, payload).then(res => {
        dispatch('getMeetingsAction', {course_id: payload.course_id})
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
    // await api.patch(`instructor/zoom-meetings/${payload.id}`, payload).then((res) => {
    //   dispatch('getMeetingsAction', {course_id: payload.course_id})
    // }).catch((err) => {
    //     console.log(err);
    // });
  },
}