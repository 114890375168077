import { api } from '@/services/axios'

export default {
  async addToCart({dispatch}, payload) {
    await api.post('/user/add-to-cart', payload)
    .then((res) => {
        dispatch('getCartItems')
    }).catch((err) => {
        console.log(err);
    });
  },
  async getCartItems({commit}) {
    await api.get('/user/add-to-cart/items')
    .then((res) => {
        commit('cartMutation', res.data.data)
    }).catch((err) => {
        console.log(err);
    });
  },
  async removeFromCart({dispatch}, payload) {
    await api.post('/user/remove-from-cart', payload)
    .then((res) => {
        dispatch('getCartItems')
    }).catch((err) => {
        console.log(err);
    });
  },
  async emptyCart({commit}) {
    await api.post('/user/empty-cart')
    .then((res) => {
        commit('cartMutation', [])
    }).catch((err) => {
        console.log(err);
    });
  },
}