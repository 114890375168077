import axios from 'axios'
var CryptoJS = require("crypto-js");

// const origin = window.location.hostname.split('.')[0]
// let central = new URL(process.env.VUE_APP_API);
let base = process.env.VUE_APP_TENANT_API


if(window.location.pathname.includes('super-admin')) {
  base = process.env.VUE_APP_API
}

const api = axios.create({ baseURL: base })

api.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*'
}

api.interceptors.request.use(request => {
  const obj = JSON.parse(localStorage.getItem('access_payload'))
  if (obj) request.headers.common['Authorization'] = `Bearer ${obj.token}`

  return request
})

const role = () => {
  const obj = JSON.parse(localStorage.getItem('access_payload'))
  let role = CryptoJS.AES.decrypt(obj.role, 'educat').toString(CryptoJS.enc.Utf8)
  
  return role.toLowerCase()
}

export default {
  install(Vue) {
    Vue.prototype.$api = api
  }
}

export { api, role }