import { api } from '@/services/axios'

export default {
  async getEnrolleesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get('/admin/enrollment/courses', { params: payload }).then(res => {
        commit('enrolleesMutation', res.data.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async getCourseEnrolleesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/enrollment/course/${payload.course_uuid}/enrollees`, {params: payload }).then(res => {
        let enrollees = []
        commit('enrolleesMutation', [])
        res.data.data.users.data.forEach(element => {
          let enrollee = {}
          enrollee.user_id = element.id
          enrollee.email = element.email
          enrollee.name = element.full_name
          enrollee.date_enrolled = element.enrollments[0].created_at
          enrollees.push(enrollee)

        });
        commit('enrolleesMutation', enrollees)
        resolve(res.data.data)
      }).catch(err => {
        console.log(err)
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async courseEnrolleesExportAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/admin/enrollment/course/${payload.course_uuid}/enrollees/export`, { ...payload }, { responseType: 'blob' }).then(res => {
        resolve(res.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async updateInvoiceStatus({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/admin/enrollment/invoice/status`, payload ).then(res => {
        commit(
          'alertMutation',
          {
            show: true,
            text: "Successfully updated!",
            type: "success"
          },
          { root: true }
        )
        resolve('ye')
      })
      .catch(errs => {
        // console.log(errs.response.data.message)
        commit(
          'alertMutation',
          {
            show: true,
            text: errs.response.data.message,
            type: "error"
          },
          { root: true }
        )
        reject(errs.response.data.message)
      })
    })
  }
}
